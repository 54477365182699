$nav-bar-height: 3.25rem;

// Z Indices
// Nav Bar
$z-index-nav-bar: 1000;

// Projects
$z-index-projects-item-title-card: 20;

// Project Detail
$z-index-project-detail: 30;

// Screens
$small-screen-size: 900px; // Nav bar
$projects-small-screen: 700px;
$project-detail-small-screen: 900px;