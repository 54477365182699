@import './style/globals';

html, body {
    overflow: hidden;
}

#app {
    position: absolute;

    width: 100%;
    height: 100%;
    overflow: hidden;

    background: $gold;

    .container, .transition-group {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
    }

    .container {
        display: flex;
        overflow: hidden;
    }

    .magnifier-image {
        width: auto;
        height: 100%;
    }

    .lazyload,
    .lazyloading {
        opacity: 0;
    }
    .lazyloaded {
        opacity: 1;
        transition: opacity 300ms;
    }
}