@import url('https://use.typekit.net/uwn4qqh.css');

$arial: Arial, sans-serif;
$ff-meta: ff-meta-web-pro, sans-serif;

$font-weight-medium: 600;

$fira-mono: fira-mono, monospace;
$farnham-text: farnham-text, serif;



@mixin meta-medium-font {
    font-family: $ff-meta;
    font-weight: $font-weight-medium;
}

@mixin farnham-text-medium-font {
    font-family: $farnham-text;
    font-weight: $font-weight-medium;
}