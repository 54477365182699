@import-normalize; /* bring in normalize.css styles */

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}
