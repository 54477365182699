@import '../../style/globals';

.about {
  position: absolute;

  overflow: hidden;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  padding-bottom: $nav-bar-height;

  background-color: $gold;

  &__download {
    position: absolute;
    top: 0.5rem;
    left: 5%;

    text-decoration: none;

    color: $black;

    font-family: $arial;
    font-size: 1rem;
  }

  &__slider {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    flex-direction: row;

    height: 100%;
    padding: 2rem 1rem 2rem 1rem;

    @media screen and (max-width: $small-screen-size) {
      padding-top: 5rem;
    }
  }

  &__slider-slide {
    flex: 0;

    height: 100%;
    padding-right: 1rem;

    // BAD BEM
    a { 
      display: block;
      height: 100%;
      
      &::after {
        content: ' ';
        background-color: transparent;
        transition: background-color 0.3s;
      }

      &:hover {
        &::after {
          content: ' ';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $thin-frost;
        }
      }
    }
  }

  &__slider-image {
    width: auto;
    height: 100%;

    border-color: $black;
    border-width: 1px;
    border-style: solid;
    transition: all 0.5s;
  }
}

.custom-cursor-link {
  position: relative;
  cursor: none;

  &__cursor {
    pointer-events: none;
    font-family: $arial;
    font-size: 1.2rem;
    color: $black;
    position: absolute;
    z-index: 10;

    &--hidden {
      display: none;
    }
  }
}